body {
  padding: auto;
  min-height: 93vh;
  font-family: -apple-system, BlinkMacSystemFont, “Segoe UI”, Roboto, Helvetica, Arial, sans-serif, “Apple Color Emoji”, “Segoe UI Emoji”, “Segoe UI Symbol”;
}

.p-button {
  box-sizing: border-box;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  cursor: pointer;
  outline: none;
  border: none;
  -webkit-tap-highlight-color: transparent;
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  vertical-align: baseline;
  text-align: center;
  margin: 0;
  min-width: 64px;
  line-height: 36px;
  padding: 0 16px;
  border-radius: 4px;
  overflow: visible;
  padding: 0 10px !important;
  border-radius: 9999px !important;
}

.p-button.p-button-secondary {
  background: #334155 !important;
}

.p-button.p-button-primary {
  background: #ED5D53 !important;
  border: none !important;
  color: white !important;
}

.p-card {
  border-radius: 1rem !important;
  padding: 1.5rem !important;
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / .1), 0 1px 2px -1px rgb(0 0 0 / .1) !important;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color) !important;
}

.helloMsg {
  line-height: 1.375 !important;
  font-size: 2.25rem !important;
  letter-spacing: -.025em !important;
  font-weight: 600 !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

.recapHome {
  line-height: 1.5rem !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  margin-left: 0.375rem !important;
}
